import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient , InMemoryCache, ApolloProvider } from '@apollo/client';

import App from './App';

const client = new ApolloClient({
 
  // uri: 'https://flyby-router-demo.herokuapp.com/',
uri: 'https://dentalapi.tomd.pl/graphql',
    // uri: 'http://zagrody21.devserwer.pl/graphql',
  cache: new InMemoryCache(),
 
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ApolloProvider client={client}>
       <App />  
     </ApolloProvider>
  </React.StrictMode>
);

