import React, { useEffect, useState } from 'react';
import Slider from '../components/Slider';
import HomeEmergency from '../components/HomeEmergency';
import Services from '../components/Services';
import Test from '../components/Test';

const Home = () => {
  return (
    <div> 
          <Slider/>
          <HomeEmergency/>
          <Services/>
          <Test/>
      </div>
  )
}

export default Home