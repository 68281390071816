import useFetch from '../utils/useFetch';
import './css/Navbar.css'
import {FaMapMarkerAlt, FaPhoneAlt, FaEnvelope} from 'react-icons/fa';
import Menu from './Menu';
import { Link } from 'react-router-dom';

const Navbar = () => {
  
const logo = useFetch('https://dentalapi.tomd.pl/wp-json/th7/v1/logo');
const adress = useFetch('https://dentalapi.tomd.pl/wp-json/th7/v1/adress');
const phone = useFetch('https://dentalapi.tomd.pl/wp-json/th7/v1/phone');
const email = useFetch('https://dentalapi.tomd.pl/wp-json/th7/v1/email');

  return (
    <div className='nav'>
        <div className='nav-top'>
            <div className='container flex-center '>
              <div className='flex-center nav-top-gap '>
                <FaMapMarkerAlt className='icon'/> 
                {adress}
              </div>
              <div className='nav-top-contact flex-center'>
                <div className='flex-center nav-top-gap'>
                  <FaPhoneAlt className='icon'/>
                {phone}</div>
                <div  className='flex-center nav-top-gap'>
                  <FaEnvelope className='icon'/>
                {email}</div>
              </div>
            </div>
        </div>
        <div className='container'>
            <div className='nav-bottom flex-center'>
              <div>
                <Link to={'/'}><img src={logo} style={{width:"214px", height:"40px"}} /></Link>
              </div>
              <div>
                <Menu/>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar