import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { gql, useQuery } from "@apollo/client";


// const GET_LOCATIONS = gql`
//   query GetLocations {
//     locations {
//       id
//       name
//       description
//       photo
//     }
//   }
// `;
// function DisplayLocations() {
//     const { loading, error, data } = useQuery(GET_LOCATIONS);
  
//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error : {error.message}</p>;
  
//     return data.locations.map(({ id, name, description, photo }) => (
//       <div key={id}>
//         <h3>{name}</h3>
//         <img width="400" height="250" alt="location-reference" src={`${photo}`} />
//         <br />
//         <b>About this location:</b>
//         <p>{description}</p>
//         <br />
//       </div>
//     ));
//   }

  const GET_ME = gql`
  query GetPostsEdges {
    posts {
      edges {
        node {
          id
          title
          date
        }
      }
    }
  }
`;

  function DisplayMe() {
    const { loading, error, data } = useQuery(GET_ME);
 
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;

        console.log(data)
    
    return (
<div>
 
        {
        data.posts.edges.map((item) => {
            return(
                <div>{item.node.title}</div>
            )

        })
        
        }

</div>


    ) 
  }
 
const Test = () => {
    const [menu, setMenu] = useState([]);

     const ourUsername = 'tomek@';
    const ourPassword = 'ovh4 jVJe RxLO ccoi msQO vNVR'
    return (
        <div>
          <h2>My first Apollo app 🚀</h2>
          <br/>
          {/* <DisplayLocations /> */}
          <DisplayMe/>
        </div>
      );
    
   
}

export default Test