import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import './css/Menu.css'

const Menu = () => {
const [menu, setMenu] =useState([]);

useEffect(()=>{
    async function loadMenu() {
                  const response = await fetch('https://dentalapi.tomd.pl/wp-json/th7/v1/menu-top');
                  if(!response.ok) {
                      // oups! something went wrong
                      return;
                  }
                  const menu_data = await response.json();
                  setMenu(menu_data);
                }
                loadMenu()
},[])

  return (
    <div>
        <ul className='flex-center menu-list'>
            {menu.map((item => { 
            return(
                <li>
                   {item.name === 'Home' ? 
                   <Link className='link' to={'/'}>{item.name}</Link>
                   :
                   <Link className='link' to={(item.name).toLowerCase()}>{item.name}</Link>
                   }
                </li>
            )
            }))}
        </ul>
    </div>
  )
}

export default Menu