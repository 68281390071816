import React, {useState, useEffect} from 'react'
import './css/Emergency.css'
import useFetch from '../utils/useFetch';
 
const HomeEmergency = () => {

const emergency = useFetch('https://dentalapi.tomd.pl/wp-json/th7/v1/emergency');
const emergency_txt1 = useFetch('https://dentalapi.tomd.pl/wp-json/th7/v1/emergency1');
const emergency_txt2 = useFetch('https://dentalapi.tomd.pl/wp-json/th7/v1/emergency2');
const [hour, setHour] =useState([]);

    useEffect(()=>{
        async function loadHour() {
            const response = await fetch('https://dentalapi.tomd.pl/wp-json/th7/v1/openhour');
                if(!response.ok) {
                // oups! something went wrong
                 return;
                     }
                const hour_data = await response.json();
                setHour(hour_data);
            }
            loadHour()
    },[])
  return (
    <div className='emergency'>
        <div className='container grid3'>
            <div>
                <h3 className='emergency-sec-title'>Get Appointment</h3>
                <p className='emergency-sec-txt'>{emergency_txt1}</p>
                <button className='btn'>Book an Appointment</button>
            </div>
            <div>
                <h3 className='emergency-sec-title'>Operating Hours</h3>
                <ul className='openhour-list'>
                    {
                        hour.map((item) => {
                            return(
                                <li>
                                    <span>{item.th7_days}</span>
                                    <span>{item.th7_hour}</span>
                                </li>
                            )
                        })
                    }
                </ul>
                     
            </div>
            <div>
                 <h3 className='emergency-sec-title'>Emergency</h3>
                 <p className='emergency-sec-number'>{emergency}</p>
                 <p className='emergency-sec-txt'>{emergency_txt2}</p>
            </div>
        </div>
    </div>
  )
}

export default HomeEmergency