import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './css/Slider.css'


const Slider = () => {
    const [sliders, setSliders] =useState([]);

    useEffect(()=>{
        async function loadSliders() {
            const response = await fetch('https://dentalapi.tomd.pl/wp-json/th7/v1/slider');
                if(!response.ok) {
                // oups! something went wrong
                 return;
                     }
                const sliders_data = await response.json();
                      setSliders(sliders_data);
            }
        loadSliders()
    },[])
    
  return (
    <Swiper
    modules={[Navigation]}
    spaceBetween={50}
    slidesPerView={1}
    navigation
  >
     {sliders.map((item) => {
            return(
                <SwiperSlide> 
                <div className='slider'  style={{
                    backgroundImage: `url(${item.th7_slider_img})`,
                }}>   
                    <div className='container'>
                        <div className='slider-content'>
                            <h1 className='slider-title'>{item.th7_slider_title}</h1>
                            <p className='slider-txt'>{item.th7_slider_content}</p>
                            <button className='btn btn-white'>
                                <Link className='link' to={item.th7_slider_btn_url}>
                                     {item.th7_slider_btn}   
                                </Link>
                            </button>
                        </div>   
                    </div>
                </div>
                </SwiperSlide>
            )
        })}
  </Swiper>
  )
}

export default Slider